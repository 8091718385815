<template>
  <div>
    <div id="appForm" class="mx-auto col-11 mt-5">
      <div class="flex flexLeft mx-auto contentsWidth">
        <p class="h4 text-center fw-3">{{year}}年度 {{name}} - 報告</p>
      </div>
      <hr class="title contentsWidth">

      <div class="notice mx-auto contentsWidth my-4 py-3 px-4">
        <p class="mb-1">・{{allPageNo}}シートすべての必要項目に入力後、登録を行ってください。</p>
        <p class="mb-1">・数字は半角、カナは全角で入力してください。</p>
      </div>

      <b-tabs
        class="gas-tab mb-3 mx-auto contentsWidth"
        nav-wrapper-class="gas-tab-wrap">
        <b-tab
          v-if="viewControl">
          <template v-slot:title>
            <span>報告情報</span>
          </template>
          <v-app-controlview
            :code="code"
            :status="status"
            :reason="reason"/>
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <span>報告者情報</span>
            <img
              v-if="hasUserErr"
              class="errorMark"
              src="@/assets/svg/error.svg">
          </template>
          <v-app-userinfo
            :writable="canSend"
            :close_datetime="close_datetime"/>
        </b-tab>
        <b-tab
          @click="scrollBar(index)"
          v-for="(page, index) in pages" :key="index">
          <template v-slot:title>
            <span>{{page.name}}</span>
            <img
              v-if="hasPageErr[index]"
              class="errorMark"
              src="@/assets/svg/error.svg">
          </template>
          <v-app-form
            :writable="canSend"
            :pageIndex="index"
            :assets="assets"
            :downloadFormFile="downloadFormFile"
            :downloadOpenFile="downloadOpenFile"/>
        </b-tab>
      </b-tabs>

      <div class="bottonArea mt-4 mb-3 flex flexCenter">
        <p
          v-show="hasEmpty"
          class="warning">
          未入力の必須項目があります。すべての必須項目に入力後、{{textIsEdit}}を行ってください。
        </p>
      </div>

      <div class="mt-4 mb-3 flex flexCenter contentsWidth">
        <b-button class="btn btn-lg mr-4 bold" to="/top">トップに戻る</b-button>
        <!-- 報告期間内かつ未提出・一時保存の場合のみ表示 -->
        <b-button
          v-if="onTime && isUnsubmited"
          @click="tempSave"
          variant="primary"
          class="btn btn-lg mr-4 bold"
          >一時保存</b-button>
        <b-button
          v-if="canSend"
          @click="save"
          variant="primary"
          class="bold mr-4"
          >{{textIsEdit}}</b-button>
        <b-button
          v-if="onTime && isSubmited"
          @click="withdraw"
          variant="dark"
          class="btn btn-primary btn-lg bold"
          >報告の削除</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/modules/api';
import download from '@/modules/download';
import Userinfo from '@/components/parts/application/Userinfo.vue';
import AppForm from '@/components/gas/parts/Form.vue';
import AppControlview from '@/components/gas/parts/Controlview.vue';
import CONST_STATUS from '@/constants/appStatus';


export default {
  name: 'gasApplicationForm',
  components: {
    'v-app-userinfo': Userinfo,
    'v-app-form': AppForm,
    'v-app-controlview': AppControlview,
  },
  data() {
    return {
      isEdit: false,
      appId: null,
      reptypeId: null,
      year: null,
      name: '',
      code: null,
      reason: '',
      status: null,
      hasUserErr: false,
      hasPageErr: [],
      initTabChange: {},
      onTime: null,
      viewLocateMsg: true,
      formStatus: null,
      assets: {},
      close_datetime: null,
      pageErrs: [],
    };
  },
  computed: {
    pages() {
      return this.$store.state.gasApplicationForm.pages;
    },
    hasEmpty() {
      // ユーザー情報
      const userInfo = this.$store.state.userInfo.inputData;
      const userInfoForm = this.$store.state.userInfoForm.form;
      const findUserResult = Object.keys(userInfo).find((key) => {
        if (!userInfoForm[key].is_required) {
          return false;
        }
        return userInfo[key] === '' || userInfo[key] === null;
      });

      // 入力フォーム
      const { inputs } = this.$store.state.gasApplicationInput;
      const formOptionData = this.$store.state.gasApplicationForm.optionDataList;
      const findFormResult = inputs.find((input) => {
        if (formOptionData[input.option_id] && !formOptionData[input.option_id].is_required) {
          return false;
        }
        const noSelect = input.field_answer_id === null;
        const notext = input.field_answer_text === '';
        const noFile = input.uploadedFileKey === '';
        return noSelect && notext && noFile;
      });
      return typeof findUserResult !== 'undefined' || typeof findFormResult !== 'undefined';
    },
    isUnsubmited() {
      return this.status === null || this.status === CONST_STATUS.APP_STATUS.TEMPSAVE;
    },
    isSubmited() {
      return this.status === CONST_STATUS.APP_STATUS.SUBMIT;
    },
    viewControl() {
      const viewControlStatusList = CONST_STATUS.GAS_VIEW_CONTROL_STATUS;
      return viewControlStatusList.indexOf(this.status) !== -1;
    },
    formStatuses() {
      return CONST_STATUS.FROM_STATUS;
    },
    canSend() {
      const isInit = this.formStatus === this.formStatuses.INIT;
      const isEdit = this.formStatus === this.formStatuses.EDIT;
      return isInit || isEdit;
    },
    hasChange() {
      return this.$store.state.gasApplicationForm.hasChange;
    },
    allPageNo() {
      return this.pages.length + 1; // 動的なページとユーザー情報の和
    },
    textIsEdit() {
      if (!this.isEdit) {
        return '報告';
      }
      return '再報告';
    },
  },
  methods: {
    async fetchApptypeData(reptypeId) {
      const param = {
        reptypeId,
      };
      const promiseFuncs = [
        api.send('/api/report/base', param),
        api.send('/api/report/myrep', param),
        api.send('/api/report/form', param),
        api.send('/api/report/edit', param),
      ];
      const responses = await api.all(promiseFuncs)
        .catch((err) => {
          console.error(err);
        });
      if (!responses) {
        await this.alert('データを取得できませんでした。');
        this.$router.push({ name: 'gasTop' });
        return;
      }
      const { base, onTime, appId } = responses[0].data;
      this.appId = appId;
      this.year = base.app_type.year;
      this.name = base.app_type.name;
      this.onTime = onTime;
      this.assets = base.assets;
      this.close_datetime = base.close_datetime;
      const { myrep, formStatus } = responses[1].data;
      this.formStatus = formStatus;
      if (myrep !== null) {
        this.code = myrep.code;
        this.reason = myrep.reason;
        this.status = myrep.status;
        // 一時保存の時はデータがあっても編集ではない
        this.isEdit = this.status !== CONST_STATUS.APP_STATUS.TEMPSAVE;
      }
      const { pages } = responses[2].data;
      const { userInfo, answers, reportId } = responses[3].data;
      this.$store.commit('gasApplicationForm/setDbData', pages);
      this.$store.commit('gasApplicationForm/setReportId', reportId);
      this.$store.commit('gasApplicationInput/setDbData', { pages, answers });
      this.$store.commit('userInfo/setDbData', userInfo);
    },
    async tempSave() {
      this.pageErrs = [];
      if (!await this.confirm('現在の内容で一時保存をしてもよろしいですか？')) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const storeData = {
        appId: this.appId,
        reptypeId: this.reptypeId,
        userInfo: JSON.stringify(this.$store.state.userInfo.inputData),
        inputs: JSON.stringify(this.$store.state.gasApplicationInput.inputs),
      };
      const { files } = this.$store.state.gasApplicationInput;
      Object.keys(files).forEach((key) => {
        storeData[key] = files[key];
      });
      const response = await api.sendForm('/api/report/tempSave', storeData)
        .catch(async (err) => {
          if (err.response.status === 422) {
            await this.alert('入力内容に誤りがありました。修正してください。', false);
            this.$store.commit('gasApplicationInput/setErrMessages', err.response.data.formErrs);
            this.$store.commit('userInfo/setErrorMsg', err.response.data.userErrs);
            this.setErrorMarks(err.response.data.userErrs);
          }
          return false;
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        return;
      }
      this.$store.commit('userInfo/resetErrorMsg');
      this.$store.commit('gasApplicationInput/initErrMessages');
      this.$store.commit('gasApplicationForm/setHasChange', false);
      this.hasUserErr = false;
      this.hasPageErr = [];
      await this.alert('一時保存しました。');
    },
    async save() {
      if (!await this.confirm('現在の内容で報告をしてもよろしいですか？')) {
        return;
      }
      this.pageErrs = [];
      this.$store.dispatch('page/onWaiting');
      const storeData = {
        appId: this.appId,
        reptypeId: this.reptypeId,
        userInfo: JSON.stringify(this.$store.state.userInfo.inputData),
        inputs: JSON.stringify(this.$store.state.gasApplicationInput.inputs),
      };
      const { files } = this.$store.state.gasApplicationInput;
      Object.keys(files).forEach((key) => {
        storeData[key] = files[key];
      });
      const response = await api.sendForm('/api/report/save', storeData)
        .catch(async (err) => {
          if (err.response.status === 422) {
            await this.alert('入力内容に誤りがありました。修正してください。', false);
            this.$store.commit('gasApplicationInput/setErrMessages', err.response.data.formErrs);
            this.$store.commit('userInfo/setErrorMsg', err.response.data.userErrs);
            this.pageErrs = err.response.data.pageErrs;
            this.setErrorMarks(err.response.data.userErrs);
          }
          return false;
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        return;
      }
      this.$store.commit('gasApplicationInput/setCode', response.data.code);
      // リダイレクト
      this.$router.push({ name: 'gasReportEnd', meta: { locate: false } });
    },
    async downloadOpenFile(assetId, assetName) {
      const param = {
        reptypeId: this.reptypeId,
        assetId,
      };
      this.$store.dispatch('page/onWaiting');
      const requireBlob = true;
      const response = await api.send('/api/report/download/file/form', param, requireBlob)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, assetName);
    },
    async downloadFormFile(fieldId, optionId, fileName) {
      const param = {
        report_id: this.$store.state.gasApplicationForm.report_id,
        field_id: fieldId,
        option_id: optionId,
        answer_no: 1, // ファイルは当面１つ固定
      };
      const url = '/api/report/download/uploadedFile';
      this.$store.dispatch('page/onWaiting');
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
    setErrorMarks(userErrs) {
      // ユーザー
      this.hasUserErr = Object.keys(userErrs).length > 0;
      // フォーム
      const { inputs } = this.$store.state.gasApplicationInput;
      Object.keys(this.pages).forEach((index) => {
        const { fields } = this.pages[index];
        const thisPageFieldIdList = Object.keys(fields).map((fieldKey) => {
          return fields[fieldKey].field_id;
        });
        let findFormErr = inputs.find((input) => {
          const searchedIndex = thisPageFieldIdList.indexOf(input.field_id);
          const hasErr = input.errMessages.length > 0;
          return searchedIndex !== -1 && hasErr;
        });
        // 例外的にページにエラーマークをつけたいとき
        if (this.pageErrs.includes(Number(index))) {
          findFormErr = true;
        }
        this.hasPageErr.splice(index, 1, findFormErr);
      });
    },
    async withdraw() {
      if (!await this.confirm('入力内容はすべて破棄されます。この報告を削除してもよろしいですか？')) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const param = {
        reptypeId: this.reptypeId,
      };
      const response = await api.send('/api/report/withdraw', param)
        .catch(async (err) => {
          if (err.response.status === 422) {
            await this.alert('入力内容に誤りがありました。修正してください。', false);
            this.$store.commit('gasApplicationInput/setErrMessages', err.response.data.formErrs);
            this.$store.commit('userInfo/setErrorMsg', err.response.data.userErrs);
            this.setErrorMarks(err.response.data.userErrs);
          }
          return false;
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        return;
      }
      await this.alert('報告を削除しました。');
      // トップへリダイレクト
      this.viewLocateMsg = false;
      this.$router.push({ name: 'gasTop' });
    },
    // ページ上部にスクロールし、スクロールバーを表示する。settimeoutがないと効かない。scrollUserinfoもなぜか効かない
    // createに書いてもだめ
    scrollBar(index) {
      if (!this.initTabChange[index]) {
        setTimeout(() => {
          document.querySelector('#scrollUserinfo').scrollTop = 1;
          Object.keys(this.pages).forEach((page) => {
            document.querySelector(`#scroll${page}`).scrollTop = 1;
          });
        }, 1);
        this.$set(this.initTabChange, index, true);
      }
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (!this.hasChange || to.name === 'gasReportEnd' || this.viewLocateMsg === false) {
      next();
    } else if (await this.confirm('入力内容は破棄されます。このページから移動してもよろしいですか？')) {
      next();
    } else {
      next(false);
    }
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    this.$store.commit('gasApplicationForm/init');
    this.$store.commit('userInfo/resetErrorMsg');
    this.$store.commit('gasApplicationForm/setIsCms', false);
    const { reptypeId } = this.$route.params;
    this.reptypeId = reptypeId;
    this.$store.commit('gasApplicationForm/setReportTypeId', reptypeId);
    await this.fetchApptypeData(reptypeId);
    this.$store.dispatch('page/offLoading');

    setTimeout(() => {
      document.querySelector('#scrollUserinfo').scrollTop = 1;
    }, 0);
  },
};
</script>

<style scoped>
  .notice {
    background-color: #F3F3F3;
    height: 80px;
  }

  .bottonArea {
    width: 1100px;
  }
  .errorMark {
    position: absolute;
    top: -7px;
    left: 150px;
  }
  .warning {
    color: #dc3545;
  }
  #appForm {
    display: block;
  }
</style>
