<template>
  <perfect-scrollbar class="p-4 ContentsBox">

    <b-row class="mt-2 pb-2 line">
      <b-col sm="3" class="pl-3 pr-1 mb-0">
        <div class="labelWrap mb-0">
          <label class="formLabel mb-0 bold">申請番号</label>
        </div>
      </b-col>
      <b-col sm="9">
        {{code}}
      </b-col>
    </b-row>
    <hr>

    <b-row class="mt-2 pb-2 line">
      <b-col sm="3" class="pl-3 pr-1 mb-0">
        <div class="labelWrap mb-0">
          <label class="formLabel mb-0 bold">ステータス</label>
        </div>
      </b-col>
      <b-col sm="9">
        {{statusTexts[status]}}
      </b-col>
    </b-row>
    <hr v-if="viewReason">

    <b-row class="mt-2 pb-2"
      v-if="viewReason">
      <b-col sm="3" class="pl-3 pr-1 mb-0">
        <div class="labelWrap mb-0">
          <label class="formLabel mb-0 bold">返却理由</label>
        </div>
      </b-col>
      <b-col sm="9">
        <b-form-textarea
          readonly
          :value="reason"
          :rows="10"
          />
      </b-col>
    </b-row>

    <div>
    </div>

  </perfect-scrollbar>
</template>

<script>
import CONST_STATUS from '@/constants/appStatus';

export default {
  name: 'gasApplicationControlview',
  components: {
  },
  props: {
    code: { type: String, default: null },
    status: { type: Number, default: null },
    reason: { type: String, default: '' },
  },
  data() {
    return {
      statusTexts: CONST_STATUS.APP_STATUS_TEXTS,
    };
  },
  methods: {
  },
  computed: {
    viewReason() {
      const hasReasonStatusList = CONST_STATUS.HAS_REASON_STATUS;
      return hasReasonStatusList.indexOf(this.status) !== -1;
    },
  },
  // ロード画面
  created() {
  },
};
</script>

<style scoped>
  .labelWrap {
    display: inline-block;
  }

  .line {
    min-height: 50px;
  }
</style>
